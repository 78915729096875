import styled from 'styled-components';
import { background, border, color, flexbox, grid, layout, overflow, position, shadow, space, zIndex } from 'styled-system';
import { motion } from 'framer-motion';

const Box = styled(motion.div)`
    ${position}
    ${flexbox}
    ${grid}
    ${space}
    ${layout}
    ${background}
    ${border}
    ${shadow}
    ${color}
    ${overflow}
    ${zIndex}
`;

export default Box;
